<template>
  <v-card>
    <v-card-title>
      <span class="pl-1 primary--text">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-form
        v-model="isFormEditValid"
        ref="formEdit"
        form="formEdit"
        id="formEdit"
        @submit.prevent="saveConfig()"
      >
        <v-row>
          <v-col cols="11" md="5" sm="6">
            <!-- Origenes -->
            <v-autocomplete
              v-model="origenSelected"
              :items="itemsOrigen"
              label="Origen"
              :rules="origenSelected == null ? ['Campor requerido'] : []"
              item-text="value"
              item-value="id"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="11" md="5" sm="6">
            <!-- Convenios -->
            <v-autocomplete
              outlined
              clearable
              dense
              v-model="convenioSelected"
              label="Convenio"
              item-text="value"
              item-value="id"
              autocomplete="on"
              :items="convenios"
              :rules="rules.required"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="py-0">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="PideToken"
              :items="optionsToken"
              label="Token"
              :rules="rules.required"
              item-text="value"
              item-value="id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="1" class="pa-0 mt-2">
            <v-tooltip left max-width="25%">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  {{ infoIcon }}
                </v-icon>
              </template>
              <span>
                El sistema determinará en el siguiente orden el ingreso del
                token del prestador:
              </span>
              <ol>
                <li>Por origen y convenios</li>
                <li>Por origen y todos los convenios</li>
                <li>
                  Por convenio y todos los orígenes
                </li>
                <li>Por todos los convenios y todos los orígenes</li>
              </ol>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="5" sm="6">
            <!-- Fecha de vigencia desde -->
            <v-menu
              ref="menu"
              v-model="menuDesde"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaDesdeTextField"
                  label="Vigencia desde"
                  :append-icon="calendarIcon"
                  v-mask="'##/##/####'"
                  hint="Formato DD/MM/AAAA"
                  @keydown.tab="menuDesde = false"
                  onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                  @blur="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                  @change="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                  clearable
                  dense
                  outlined
                  autocomplete="off"
                  :rules="
                    rules.required.concat([
                      rules.validDateRange(fechaDesde, fechaHasta)
                    ])
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesde"
                no-title
                scrollable
                @change="fechaDesdeTextField = formatDate(fechaDesde)"
                @input="menuDesde = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="5" sm="6">
            <!-- Fecha de vigencia hasta -->
            <v-menu
              ref="menu2"
              v-model="menuHasta"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaHastaTextField"
                  label="Vigencia hasta"
                  :append-icon="calendarIcon"
                  @keydown.tab="menuHasta = false"
                  v-mask="'##/##/####'"
                  onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                  hint="Formato DD/MM/AAAA"
                  @blur="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                  @change="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                  clearable
                  dense
                  :rules="
                    rules.required.concat([
                      rules.validDateRange(fechaDesde, fechaHasta)
                    ])
                  "
                  outlined
                  autocomplete="off"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>

              <v-date-picker
                v-model="fechaHasta"
                no-title
                scrollable
                @change="fechaHastaTextField = formatDate(fechaHasta)"
                @input="menuHasta = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>Grupos a excluir del pedido de token</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="10" class="py-0">
                    <v-select
                      v-model="agruPracticasSelected"
                      :items="agruPracticas"
                      label="Agrupación de prácticas"
                      return-object
                      item-text="value"
                      item-value="id"
                      clearable
                      @change="setGrupos(), clearGrupo()"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10" class="py-0">
                    <v-select
                      outlined
                      clearable
                      dense
                      :disabled="agruPracticasSelected == null"
                      multiple
                      item-text="value"
                      item-value="id"
                      :rules="
                        agruPracticasSelected != null &&
                        grupoPracticas.length == 0
                          ? ['Campo requerido']
                          : []
                      "
                      return-object
                      v-model="grupoPracticas"
                      label="Grupo de prácticas"
                      :items="itemsGrupoPractica"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.value }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ grupoPracticas.length - 1 }} otros)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="1" class="pa-0 mt-2">
                    <v-tooltip left max-width="25%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>
                        Para elegir grupo/s de práctica, debe seleccionar una
                        agrupación
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn class="m-2" outlined @click="closeModal">Cancelar</v-btn>
      <v-btn
        type="submit"
        :disabled="!isFormEditValid"
        form="formEdit"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  props: {
    tokenConfigId: {
      type: Number,
      default: null,
      require: true
    },
    isEdit: {
      type: Boolean,
      require: true
    }
  },
  directives: { mask },
  data: vm => ({
    infoIcon: enums.icons.SNB_INFO,
    agruPracticas: [],
    grupoPracticas: [],
    itemsGrupoPractica: [],
    itemsOrigen: [],
    calendarIcon: enums.icons.CALENDAR,
    fechaDesde: null,
    fechaDesdeTextField: null,
    menuDesde: false,
    agruPracticasSelected: null,
    fechaHasta: null,
    fechaHastaTextField: null,
    menuHasta: false,
    rules: rules,
    isFormEditValid: false,
    formTitle: "Editar configuración",
    convenioSelected: null,
    convenios: [],
    origenSelected: null,
    origenes: [],
    objetoResultado: {},
    ejemplo: null,
    PideToken: null,
    optionsToken: []
  }),
  async created() {
    await this.loadParameters();
    if (this.tokenConfigId != null) {
      this.setConfig();
    } else {
      this.formTitle = "Nueva configuración";
    }
  },
  methods: {
    ...mapActions({
      getAgrupacionesPracticas: "analytics/getAgrupacionesPracticas",
      getGrupoPorPractica: "analytics/getGrupoPorPractica",
      fetchConvenios: "configAppBenef/fetchConvenios",
      getOrigenes: "configAppBenef/getOrigenes",
      getTokenConfigById: "configAppBenef/getTokenConfigById",
      fetchPideToken: "configAppBenef/fetchPideToken",
      saveTokenConfig: "configAppBenef/saveTokenConfig",
      setAlert: "user/setAlert"
    }),
    async loadParameters() {
      this.convenios = await this.fetchConvenios();
      this.convenios.unshift({ id: -1, value: "Todos" });
      const origenes = await this.getOrigenes();
      this.itemsOrigen = origenes;
      this.itemsOrigen.unshift({
        id: 0,
        value: "Todos"
      });
      this.optionsToken = await this.fetchPideToken();
      const agrup = await this.getAgrupacionesPracticas();
      this.agruPracticas = agrup;
    },
    async setGrupos() {
      if (this.agruPracticasSelected != null) {
        const grupo = await this.getGrupoPorPractica(
          this.agruPracticasSelected.id
        );
        this.itemsGrupoPractica = grupo;
      }
    },
    async saveConfig() {
      const gruPracticas = this.grupoPracticas;
      this.objetoResultado = gruPracticas;
      const data = {
        TokenConfigId: this.tokenConfigId,
        OsId: this.convenioSelected === -1 ? null : this.convenioSelected,
        OriId: this.origenSelected === 0 ? null : this.origenSelected,
        PideTokenId: this.PideToken,
        VigenciaDesde: this.fechaDesde,
        VigenciaHasta: this.fechaHasta,
        AgruPraId: this.agruPracticasSelected?.id,
        GruposId: this.objetoResultado.map(x => x.id ?? 0)
      };
      const response = await this.saveTokenConfig(data);
      if (response.status === 200)
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      this.closeModal();
    },
    async setConfig() {
      this.isEdit;
      const response = await this.getTokenConfigById(this.tokenConfigId);
      this.convenioSelected =
        this.isEdit == true && response.osId == null ? -1 : response.osId;
      this.origenSelected =
        this.isEdit == true && response.oriId == null ? 0 : response.oriId;
      this.PideToken = response.pideTokenId;
      this.fechaDesdeTextField = response.vigenciaDesde;
      this.fechaDesde = this.parseDateToIso(this.fechaDesdeTextField);
      this.fechaHastaTextField = response.vigenciaHasta;
      this.fechaHasta = this.parseDateToIso(this.fechaHastaTextField);
      //Lo que me llega por prop es el respone agruPraId
      const agruPra = this.agruPracticas.find(y => y.id === response.agruPraId);
      this.agruPracticasSelected = agruPra ? agruPra : null;
      await this.setGrupos();
      //setear el v-model con los grupos de practicas que esten seleccionados a partir de los items
      this.grupoPracticas = response.listaGrupos;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    clearGrupo() {
      this.grupoPracticas = [];
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
