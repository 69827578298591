<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card class="mb-4">
          <v-card-text class="py-0">
            <v-form
              v-model="isFormValid"
              ref="form"
              form="form"
              id="form"
              @submit.prevent="saveParameters()"
            >
              <v-row class="ml-1">
                <v-col cols="3" class="pb-0 px-0 mr-6">
                  <v-subheader class="px-2">
                    Habilitar el uso del token en autorizaciones
                    <v-switch
                      class="ml-4"
                      v-model="habilitaVigenciaToken"
                      item-text="value"
                      item-value="id"
                      dense
                      outlined
                      :disabled="!canEnable"
                      @change="changeVigenciaToken()"
                    ></v-switch>
                  </v-subheader>
                </v-col>
                <v-col cols="3" md="3" class="pb-0">
                  <v-text-field
                    v-model="minsVigenciaToken"
                    outlined
                    dense
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    label="Tiempo de vigencia"
                    suffix="mins"
                    ref="mins-vigencia-token"
                    :rules="
                      habilitaVigenciaToken
                        ? rules.required.concat(
                            rules.maxNumber(minsVigenciaToken, 99999)
                          )
                        : []
                    "
                    :disabled="!canEnable || !habilitaVigenciaToken"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="pb-0 px-0" v-if="habilitaVigenciaToken">
                  <v-subheader class="px-0">
                    Habilitar token maestro
                    <v-switch
                      class="ml-4"
                      v-model="habilitaTokenMaestro"
                      dense
                      outlined
                    ></v-switch>
                  </v-subheader>
                </v-col>
                <v-col cols="3" md="2" class="pb-0">
                  <v-text-field
                    v-model="tokenMaestro"
                    outlined
                    dense
                    autocomplete="off"
                    v-mask="'######'"
                    label="Token maestro"
                    :disabled="!habilitaTokenMaestro"
                    hint="Número de 6 dígitos"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!isFormValid"
              color="primary"
              form="form"
              type="submit"
              v-if="canEnable"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="tokenConfigs"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          item-key="tokenConfigId"
          show-expand
          :expanded.sync="expanded"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="3" class="py-0 pr-0 d-flex align-center">
                  <v-card-subtitle class="p-0"
                    >Configuración de prestadores</v-card-subtitle
                  >
                </v-col>
                <v-col cols="7" class="pl-0">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item.tokenConfigId)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar configuración</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteConfig(item.tokenConfigId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar configuración</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-show="item.listaGrupos.length > 0"
                  v-on="on"
                  >{{ isExpanded ? chevronUpIcon : chevronDownIcon }}</v-icon
                >
              </template>
              <span>Ver datos de agrupación</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12" md="12">
                  <strong>Grupos excluídos:</strong>
                  <ul>
                    <li v-for="grupo in item.listaGrupos" :key="grupo.id">
                      {{ grupo.value }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <v-dialog
          v-if="openModalEdit"
          v-model="openModalEdit"
          max-width="45%"
          @keydown.esc="openModalEdit = false"
          persistent
        >
          <edit-token
            :tokenConfigId="tokenConfigId"
            :isEdit="isEdit"
            @closeAndReload="closeAndReload"
          />
        </v-dialog>
        <DeleteDialog
          max-width="34%"
          :titleProp="titleDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditToken from "../../../../components/modules/appBenef/configuracion/EditToken.vue";
import rules from "@/utils/helpers/rules";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditToken,
    Ayuda
  },
  directives: { mask },

  name: "Token",
  data() {
    return {
      title: enums.titles.TOKEN,
      optionCode: enums.webSiteOptions.TOKEN,
      showExpand: false,
      showHelp: false,
      expanded: [],
      showIcon: true,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      rules: rules,
      headers: [
        {
          text: "Origen",
          align: "start",
          value: "oriNombre",
        },
        {
          text: "Convenio",
          align: "start",
          value: "osNombre",
          sortable: false
        },
        {
          text: "Pide token",
          align: "start",
          value: "pideTokenNom",
          sortable: false
        },
        {
          text: "Vig. desde",
          align: "start",
          value: "vigenciaDesde",
          sortable: false
        },
        {
          text: "Vig. hasta",
          align: "start",
          value: "vigenciaHasta",
          sortable: false
        },
        {
          text: "Agrupación",
          align: "start",
          value: "agruPraNombre",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      tokenConfigs: [],
      search: "",
      routeToGo: "ConfiguracionAppBenef",
      canEdit: false,
      canDelete: false,
      canCreate: false,
      openModalEdit: false,
      isLoading: false,
      titleDelete: "¿Desea eliminar la configuración?",
      showDeleteModal: false,
      allowedActions: null,
      idToDelete: null,
      isFormValid: false,
      habilitaVigenciaToken: null,
      minsVigenciaToken: null,
      habilitaTokenMaestro: false,
      tokenMaestro: "",
      tokenConfigId: null,
      isEdit: false,
      canEnable: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadConfig();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      fetchTokenConfig: "configAppBenef/fetchTokenConfig",
      deleteTokenConfig: "configAppBenef/deleteTokenConfig",
      saveParametersToken: "configAppBenef/saveParametersToken",
      setParametersToken: "configAppBenef/setParametersToken",
      getPideToken: "configAppBenef/getPideToken",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_TOKEN:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_TOKEN:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_TOKEN:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.HABILITAR_TOKEN:
            this.canEnable = true;
            break;
          default:
            break;
        }
      });
    },
    changeVigenciaToken() {
      if (!this.habilitaVigenciaToken) {
        this.minsVigenciaToken = null;
        this.tokenMaestro = "";
        this.habilitaTokenMaestro = false;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async loadConfig() {
      this.isLoading = true;
      this.setParameters();
      this.tokenConfigs = await this.fetchTokenConfig();
      this.tokenConfigs.forEach(x => {
        if (x.oriNombre == null) {
          x.oriNombre = "Todos";
        }
        if (x.osNombre == null) {
          x.osNombre = "Todos";
        }
      });
      this.isLoading = false;
    },
    async confirmDelete() {
      const response = await this.deleteTokenConfig({
        id: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadConfig();
      }
    },
    async saveParameters() {
      if (
        this.habilitaTokenMaestro &&
        (this.tokenMaestro == null || this.tokenMaestro.length != 6)
      ) {
        this.setAlert({
          type: "warning",
          message: "Asegúrese de ingresar un token maestro de 6 dígitos"
        });
      } else {
        const response = await this.saveParametersToken({
          tokenHabilitado: this.habilitaVigenciaToken,
          tokenVigenciaMinutos: this.minsVigenciaToken,
          tokenMaestro: this.habilitaTokenMaestro ? this.tokenMaestro : null
        });
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.loadConfig();
        }
      }
    },
    async setParameters() {
      const response = await this.setParametersToken();
      this.habilitaVigenciaToken = response.tokenHabilitado;
      this.minsVigenciaToken = response.tokenVigenciaMinutos;
      this.habilitaTokenMaestro = response.tokenMaestro !== null;
      this.tokenMaestro = response.tokenMaestro;
    },
    deleteConfig(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    openModal(id) {
      this.openModalEdit = true;
      this.tokenConfigId = id;
      if (id != null) {
        this.isEdit = true;
      }
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadConfig();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
