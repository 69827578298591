var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"formEdit",attrs:{"form":"formEdit","id":"formEdit"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormEditValid),callback:function ($$v) {_vm.isFormEditValid=$$v},expression:"isFormEditValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11","md":"5","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsOrigen,"label":"Origen","rules":_vm.origenSelected == null ? ['Campor requerido'] : [],"item-text":"value","item-value":"id","outlined":"","dense":""},model:{value:(_vm.origenSelected),callback:function ($$v) {_vm.origenSelected=$$v},expression:"origenSelected"}})],1),_c('v-col',{attrs:{"cols":"11","md":"5","sm":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","clearable":"","dense":"","label":"Convenio","item-text":"value","item-value":"id","autocomplete":"on","items":_vm.convenios,"rules":_vm.rules.required},model:{value:(_vm.convenioSelected),callback:function ($$v) {_vm.convenioSelected=$$v},expression:"convenioSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","clearable":"","items":_vm.optionsToken,"label":"Token","rules":_vm.rules.required,"item-text":"value","item-value":"id"},model:{value:(_vm.PideToken),callback:function ($$v) {_vm.PideToken=$$v},expression:"PideToken"}})],1),_c('v-col',{staticClass:"pa-0 mt-2",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"left":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" El sistema determinará en el siguiente orden el ingreso del token del prestador: ")]),_c('ol',[_c('li',[_vm._v("Por origen y convenios")]),_c('li',[_vm._v("Por origen y todos los convenios")]),_c('li',[_vm._v(" Por convenio y todos los orígenes ")]),_c('li',[_vm._v("Por todos los convenios y todos los orígenes")])])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"5","sm":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia desde","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","dense":"","outlined":"","autocomplete":"off","rules":_vm.rules.required.concat([
                    _vm.rules.validDateRange(_vm.fechaDesde, _vm.fechaHasta)
                  ])},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuDesde = false},"blur":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]},"change":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]}},model:{value:(_vm.fechaDesdeTextField),callback:function ($$v) {_vm.fechaDesdeTextField=$$v},expression:"fechaDesdeTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaDesdeTextField = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"5","sm":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia hasta","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"Formato DD/MM/AAAA","clearable":"","dense":"","rules":_vm.rules.required.concat([
                    _vm.rules.validDateRange(_vm.fechaDesde, _vm.fechaHasta)
                  ]),"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuHasta = false},"blur":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]},"change":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]}},model:{value:(_vm.fechaHastaTextField),callback:function ($$v) {_vm.fechaHastaTextField=$$v},expression:"fechaHastaTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaHastaTextField = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Grupos a excluir del pedido de token")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-select',{attrs:{"items":_vm.agruPracticas,"label":"Agrupación de prácticas","return-object":"","item-text":"value","item-value":"id","clearable":"","outlined":"","dense":""},on:{"change":function($event){_vm.setGrupos(), _vm.clearGrupo()}},model:{value:(_vm.agruPracticasSelected),callback:function ($$v) {_vm.agruPracticasSelected=$$v},expression:"agruPracticasSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","disabled":_vm.agruPracticasSelected == null,"multiple":"","item-text":"value","item-value":"id","rules":_vm.agruPracticasSelected != null &&
                      _vm.grupoPracticas.length == 0
                        ? ['Campo requerido']
                        : [],"return-object":"","label":"Grupo de prácticas","items":_vm.itemsGrupoPractica},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.grupoPracticas.length - 1)+" otros) ")]):_vm._e()]}}]),model:{value:(_vm.grupoPracticas),callback:function ($$v) {_vm.grupoPracticas=$$v},expression:"grupoPracticas"}})],1),_c('v-col',{staticClass:"pa-0 mt-2",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"left":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" Para elegir grupo/s de práctica, debe seleccionar una agrupación ")])])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"m-2",attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormEditValid,"form":"formEdit","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }